<template>
  <div>
    <CCards
      icon="fa fa-filter"
      bIcon="fa fa-file"
      caption="Datos del Filtro"
      btn_name="ocultar"
      :button1="cp_button_down"
      btn_name1="Descargar Reporte"
      @show_down="down_pdf"
    >
      <CRow>
        <CCol sm="3">
          <cSelectForm
            label="Seleccione Proyecto"
            :options="data_projects"
            placeholder="Escoja proyecto"
            :value.sync="form_add_project.id"
          />
        </CCol>
        <CCol sm="2">
            <CInput  
                label="Fecha Inicio"  
                type="date" 
                v-model="filtro.dateini"
            />
        </CCol>
        <CCol sm="2">
            <CInput 
                label="Fecha Final"  
                type="date" 
                v-model="filtro.datefin" 
                :min="filtro.dateini"
            />
        </CCol>
        <CCol sm="3">
          <cSelectForm
            label="Seleccione Conductor"
            :options="data_drivers"
            placeholder="Escoja Conductor"
            :value.sync="form_add_driver.id"
            @change="enable_filter"
          />
        </CCol>
        <CCol sm="2">
          <button
            type="button"
            :disabled="vue.btnestado == 1 ? true : false"
            :class="btnClasses"
            style="margin-top: 2em; width: 100% !important"
            @click="loadTable"
          >
            <i class="fas fa-search"></i>&nbsp; Filtrar
          </button>
        </CCol>
      </CRow>
    </CCards>

    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          striped
          border
          small
          fixed
          :caption="vue.texto"
          icon="fa fa-file"
          btn_name="ocultar"
          :showhide_buttons="vue.showhide"
          :size="'md'"
          @action_ver="down_item_pdf"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CCards from "../../components/shared/containers/Cards.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: { CTableWrapper, CCards, cSelectForm },
  data() {
    return {
      vue: {
        estado: 1,
        btnestado: 1,
        texto: "Resultados del Filtro",
        showhide: [
          {
            btn_ver: 1,
            btn_pass: 0,
            btn_edit: 0,
            btn_del: 0,
          },
        ], // dato para los estados de los botones
      },
      fields: [
        "Id",
        "Proyecto",
        "Cliente",
        "Fecha",
        "Movilidad",
        "Ejecucion_venta",
      ],
      filtro: {
        dateini: "",
        datefin: "",
      },
      form_add_project: {
        id: "",
      },
      form_add_driver: {
        id: "",
      },
      data: [],
      data_projects: [],
      data_drivers: [],
    };
  },
  computed: {
    btnClasses() {
      return [`btn btn-${this.color || "primary"}`];
    },
    cp_button_down: function () {
      if (this.form_add_driver.id != "") return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      /** Projects */
      this.get({
        url: this.$store.getters.get__url + "/project",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_projects = response.data_project;
        })
        .catch((errors) => {});

      /** Drivers */
      this.get({
        url: this.$store.getters.get__url + "/driver",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data_driver.unshift({'value':'0','label':'TODOS'});
          this.data_drivers = response.data_driver;
        })
        .catch((errors) => {});
      let actual = new Date();
      let fecha;
      fecha = actual.getFullYear() + '-'
              + ('0' + (actual.getMonth()+1)).slice(-2) + '-'
              + ('0' + actual.getDate()).slice(-2);
      this.filtro.dateini = fecha;
      this.filtro.datefin = fecha;
    },
    enable_filter: function () {
      this.vue.btnestado = 0;
    },
    loadTable: function () {
      let fecha1, fecha2, project_id, driver_id;

      fecha1 = this.filtro.dateini;
      fecha2 = this.filtro.datefin;
      project_id = this.form_add_project.id;
      driver_id = this.form_add_driver.id;

      this.post({
        url: this.$store.getters.get__url + "/visit/report_visit",
        token: this.$store.getters.get__token,
        params: {
          fecha_ini: fecha1,
          fecha_fin: fecha2,
          project_id: project_id,
          driver_id: driver_id,
        },
      }).then((response) => {
        this.data = response.data;
      }).catch((errors) => {});
    },
    down_pdf: function(){
      let fecha1, fecha2, project_id, driver_id;

      fecha1 = this.filtro.dateini;
      fecha2 = this.filtro.datefin;
      project_id = this.form_add_project.id;
      driver_id = this.form_add_driver.id;

      this.post({
        url: this.$store.getters.get__url + "/reports/visitslist",
        token: this.$store.getters.get__token,
        params: {
          fecha_ini: fecha1,
          fecha_fin: fecha2,
          project_id: project_id,
          driver_id: driver_id,
        },
      }).then((response) => {
        window.open("/assets/visitas_lista/vistas_lista.pdf", "_blank");
      }).catch((errors) => {});

    },
    down_item_pdf: function (id) {
      
      this.post({
        url: this.$store.getters.get__url + "/reports/visits",
        token: this.$store.getters.get__token,
        params: {
          id: id,
        },
      }).then((response) => {
        window.open("/assets/visitas/" + response + ".pdf", "_blank");
      }).catch((errors) => {});

    },
  },
};
</script>